<p-card styleClass="gallery-image-card">
  <ng-template pTemplate="header">
    <div class="image-container">
      <p-skeleton borderRadius="0" height="100%"></p-skeleton>
    </div>
  </ng-template>
  <div class="tags">
    <p-skeleton width="100px" height="1.5rem"></p-skeleton>
    <p-skeleton width="100px" height="1.5rem"></p-skeleton>
  </div>
</p-card>
