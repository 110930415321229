export enum MenuItemType {
  MENU_PAGE = 'MENU_PAGE' /** t(MENU_PAGE) **/,
  TOPO = 'TOPO' /** t(TOPO) **/,
  ASCENTS = 'ASCENTS' /** t(ASCENTS) **/,
  RANKING = 'RANKING' /** t(RANKING) **/,
  NEWS = 'NEWS' /** t(NEWS) **/,
  YOUTUBE = 'YOUTUBE' /** t(YOUTUBE) **/,
  INSTAGRAM = 'INSTAGRAM' /** t(INSTAGRAM) **/,
  GALLERY = 'GALLERY' /** t(GALLERY) **/,
}
