<ng-container *transloco="let t; read: 'ascents.ascentList'">
  <p-dataView
    #dv
    [value]="ascents"
    [emptyMessage]="t('noAscentsFoundMessage')"
    [loading]="loadingFirstPage === loadingStates.LOADING"
  >
    <ng-template pTemplate="header">
      <div
        class="flex flex-column sm:flex-column justify-content-between gap-2"
      >
        <div
          class="gap-2 flex align-items-start sm:align-items-center flex-column sm:flex-row"
        >
          {{ t("orderByLabel") }}
          <div>
            <p-dropdown
              [options]="orderOptions"
              [(ngModel)]="orderKey"
              optionLabel="label"
              (onChange)="loadFirstPage()"
              styleClass="md:mb-0"
            >
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="orderKey">
                  <div>{{ orderKey.label }}</div>
                </div>
              </ng-template>
              <ng-template let-option pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div>{{ option.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <p-dropdown
              [options]="orderDirectionOptions"
              [(ngModel)]="orderDirectionKey"
              optionLabel="label"
              (onChange)="loadFirstPage()"
              styleClass="md:mb-0 sm:ml-2 sm:mt-0 mt-2"
            >
              <ng-template pTemplate="selectedItem">
                <div
                  class="flex align-items-center gap-2"
                  *ngIf="orderDirectionKey"
                >
                  <div>{{ orderDirectionKey.label }}</div>
                </div>
              </ng-template>
              <ng-template let-option pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div>{{ option.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>

        <div
          class="slider-container mt-1"
          *ngIf="!disableGradeOrderAndFiltering"
        >
          <lc-slider-labels
            [rangeMin]="gradeFilterRange[0]"
            [rangeMax]="gradeFilterRange[1]"
            [min]="minGradeValue"
            [max]="maxGradeValue"
            [minLabel]="gradeNameByValue['FB'][gradeFilterRange[0]] | transloco"
            [maxLabel]="gradeNameByValue['FB'][gradeFilterRange[1]] | transloco"
          ></lc-slider-labels>
          <p-slider
            [(ngModel)]="gradeFilterRange"
            [min]="minGradeValue"
            [max]="maxGradeValue"
            [range]="true"
            [step]="1"
            (touchstart)="listenForSliderStop = true"
            (mousedown)="listenForSliderStop = true"
          />
        </div>
      </div>
    </ng-template>
    <ng-template let-ascents pTemplate="list">
      <div class="grid grid-nogutter">
        <div
          class="col-12"
          *ngFor="let ascent of ascents; let first = first"
          data-cy="ascent-list-item"
        >
          <div
            class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4 ascent-table-container"
            [ngClass]="{ 'border-top-1 surface-border': !first }"
          >
            <!-- DISPLAY FOR LARGE SCREENS -->
            <div class="ascent-table-row-large">
              <div class="ascent-table-user-info" *ngIf="!user">
                <p-avatar
                  [image]="
                    ascent.createdBy.avatar
                      ? ascent.createdBy.avatar.thumbnailS
                      : 'assets/user.png'
                  "
                  styleClass="mr-2"
                  size="large"
                  shape="circle"
                ></p-avatar>
                <a
                  class="username"
                  routerLink="{{ ascent.routerLinkCreatedBy }}"
                  >{{ ascent.createdBy.firstname }}
                  {{ ascent.createdBy.lastname }}</a
                >
              </div>
              <div class="ascent-table-line-info">
                <div class="ascent-table-line-name">
                  <a routerLink="{{ ascent.routerLinkLine }}">{{
                    ascent.line.name
                  }}</a>
                  <div class="grade">
                    {{ ascent.line.grade.name | transloco }}
                  </div>
                </div>
                <div class="ascent-table-line-location mt-1">
                  <a routerLink="{{ ascent.routerLinkCrag }}">{{
                    ascent.crag.name
                  }}</a
                  >,
                  <a routerLink="{{ ascent.routerLinkSector }}">{{
                    ascent.sector.name
                  }}</a
                  >,
                  <a routerLink="{{ ascent.routerLinkArea }}">{{
                    ascent.area.name
                  }}</a>
                </div>
              </div>
              <div class="ascent-table-comment">
                {{ ascent.comment }}
              </div>
              <div class="ascent-table-rating">
                <p-rating
                  [ngModel]="ascent.rating"
                  [readonly]="true"
                  [cancel]="false"
                ></p-rating>
              </div>
              <div class="ascent-table-grade">
                <div class="grade">
                  <i
                    class="pi pi-angle-double-up"
                    style="color: green"
                    *ngIf="ascent | upgrade"
                  ></i>
                  <i class="pi pi-check" *ngIf="ascent | consensusGrade"></i>
                  <i
                    class="pi pi-angle-double-down"
                    style="color: red"
                    *ngIf="ascent | downgrade"
                  ></i>
                  <div class="ml-1 grade-name">
                    {{ ascent.grade.name | transloco }}
                    <span *ngIf="ascent.soft">&nbsp;({{ t("soft") }})</span>
                    <span *ngIf="ascent.hard">&nbsp;({{ t("hard") }})</span>
                  </div>
                </div>
              </div>
              <div class="ascent-table-properties">
                <p-tag
                  severity="primary"
                  value="{{ t('fa') }}"
                  *ngIf="ascent.fa"
                  icon="pi pi-star"
                ></p-tag>
                <p-tag
                  severity="primary"
                  value="{{ t('flash') }}"
                  *ngIf="ascent.flash"
                  icon="pi pi-bolt"
                ></p-tag>
                <p-tag
                  severity="primary"
                  value="{{ t('withKneepad') }}"
                  *ngIf="ascent.withKneepad"
                  icon="pi pi-ticket"
                  data-cy="kneepadtag"
                ></p-tag>
              </div>
              <div class="ascent-table-date">
                <i class="pi pi-calendar"></i>
                <ng-container *ngIf="ascent.year">{{
                  ascent.year
                }}</ng-container>
                <ng-container *ngIf="ascent.date">{{
                  ascent.date | date: "dd.MM.yyyy"
                }}</ng-container>
              </div>
              <div class="ascent-actions">
                <p-menu
                  #menu
                  [model]="ascentActionItems"
                  [popup]="true"
                  [appendTo]="'body'"
                ></p-menu>
                <p-button
                  icon="pi pi-ellipsis-v"
                  styleClass="p-button-rounded p-button-text"
                  data-cy="ascent-actions-button"
                  *isOwnUser="ascent.createdBy"
                  (click)="clickedAscentForAction = ascent; menu.toggle($event)"
                ></p-button>
              </div>
            </div>

            <!-- DISPLAY FOR MEDIUM SCREENS -->
            <div class="ascent-table-row-medium">
              <div class="ascent-table-user-and-name-info">
                <p-avatar
                  [image]="
                    ascent.createdBy.avatar
                      ? ascent.createdBy.avatar.thumbnailS
                      : 'assets/user.png'
                  "
                  styleClass="mr-2"
                  size="large"
                  shape="circle"
                  *ngIf="!user"
                ></p-avatar>
                <div class="name-with-line">
                  <a
                    class="username"
                    *ngIf="!user"
                    routerLink="{{ ascent.routerLinkCreatedBy }}"
                    >{{ ascent.createdBy.firstname }}
                    {{ ascent.createdBy.lastname }}</a
                  >
                  <div class="ascent-table-line-name">
                    <a routerLink="{{ ascent.routerLinkLine }}">{{
                      ascent.line.name
                    }}</a>
                    <div class="grade">
                      {{ ascent.line.grade.name | transloco }}
                    </div>
                  </div>
                  <div class="ascent-table-line-location mt-1">
                    <a routerLink="{{ ascent.routerLinkCrag }}">{{
                      ascent.crag.name
                    }}</a
                    >,
                    <a routerLink="{{ ascent.routerLinkSector }}">{{
                      ascent.sector.name
                    }}</a
                    >,
                    <a routerLink="{{ ascent.routerLinkArea }}">{{
                      ascent.area.name
                    }}</a>
                  </div>
                </div>
              </div>
              <div class="ascent-table-comment">
                {{ ascent.comment }}
              </div>
              <div class="ascent-table-rating">
                <p-rating
                  [ngModel]="ascent.rating"
                  [readonly]="true"
                  [cancel]="false"
                ></p-rating>
              </div>
              <div class="ascent-table-grade">
                <div class="grade">
                  <i
                    class="pi pi-angle-double-up"
                    style="color: green"
                    *ngIf="ascent | upgrade"
                  ></i>
                  <i class="pi pi-check" *ngIf="ascent | consensusGrade"></i>
                  <i
                    class="pi pi-angle-double-down"
                    style="color: red"
                    *ngIf="ascent | downgrade"
                  ></i>
                  <div class="ml-1 grade-name">
                    {{ ascent.grade.name | transloco }}
                    <span *ngIf="ascent.soft">&nbsp;({{ t("soft") }})</span>
                    <span *ngIf="ascent.hard">&nbsp;({{ t("hard") }})</span>
                  </div>
                </div>
              </div>
              <div class="ascent-table-properties">
                <p-tag
                  severity="primary"
                  value="{{ t('fa') }}"
                  *ngIf="ascent.fa"
                  icon="pi pi-star"
                ></p-tag>
                <p-tag
                  severity="primary"
                  value="{{ t('flash') }}"
                  *ngIf="ascent.flash"
                  icon="pi pi-bolt"
                ></p-tag>
                <p-tag
                  severity="primary"
                  value="{{ t('withKneepad') }}"
                  *ngIf="ascent.withKneepad"
                  icon="pi pi-ticket"
                ></p-tag>
              </div>
              <div class="ascent-table-date">
                <i class="pi pi-calendar"></i>
                <ng-container *ngIf="ascent.year">{{
                  ascent.year
                }}</ng-container>
                <ng-container *ngIf="ascent.date">{{
                  ascent.date | date: "dd.MM.yyyy"
                }}</ng-container>
              </div>
              <div class="ascent-actions">
                <p-menu
                  #menu
                  [model]="ascentActionItems"
                  [popup]="true"
                  [appendTo]="'body'"
                ></p-menu>
                <p-button
                  icon="pi pi-ellipsis-v"
                  styleClass="p-button-rounded p-button-text"
                  *isOwnUser="ascent.createdBy"
                  (click)="clickedAscentForAction = ascent; menu.toggle($event)"
                ></p-button>
              </div>
            </div>

            <!-- DISPLAY FOR SMALL SCREENS -->
            <div class="ascent-table-row-small">
              <div class="ascent-table-user-and-name-info">
                <p-avatar
                  [image]="
                    ascent.createdBy.avatar
                      ? ascent.createdBy.avatar.thumbnailS
                      : 'assets/user.png'
                  "
                  styleClass="mr-3"
                  size="large"
                  shape="circle"
                  *ngIf="!user"
                ></p-avatar>
                <div class="name-with-line">
                  <a
                    class="username"
                    *ngIf="!user"
                    routerLink="{{ ascent.routerLinkCreatedBy }}"
                    >{{ ascent.createdBy.firstname }}
                    {{ ascent.createdBy.lastname }}</a
                  >
                  <div class="ascent-table-line-name">
                    <a routerLink="{{ ascent.routerLinkLine }}">{{
                      ascent.line.name
                    }}</a>
                    <div class="grade">
                      {{ ascent.line.grade.name | transloco }}
                    </div>
                  </div>
                  <div class="ascent-table-line-location mt-1">
                    <a routerLink="{{ ascent.routerLinkCrag }}">{{
                      ascent.crag.name
                    }}</a
                    >,
                    <a routerLink="{{ ascent.routerLinkSector }}">{{
                      ascent.sector.name
                    }}</a
                    >,
                    <a routerLink="{{ ascent.routerLinkArea }}">{{
                      ascent.area.name
                    }}</a>
                  </div>
                </div>
                <div class="ascent-actions">
                  <p-menu
                    #menu
                    [model]="ascentActionItems"
                    [popup]="true"
                    [appendTo]="'body'"
                  ></p-menu>
                  <p-button
                    icon="pi pi-ellipsis-v"
                    styleClass="p-button-rounded p-button-text"
                    *isOwnUser="ascent.createdBy"
                    (click)="
                      clickedAscentForAction = ascent; menu.toggle($event)
                    "
                  ></p-button>
                </div>
              </div>
              <div class="ascent-table-comment">
                {{ ascent.comment }}
              </div>
              <div class="property-row-very-small">
                <p-tag
                  severity="primary"
                  value="{{ t('fa') }}"
                  *ngIf="ascent.fa"
                  icon="pi pi-star"
                ></p-tag>
                <p-tag
                  severity="primary"
                  value="{{ t('flash') }}"
                  *ngIf="ascent.flash"
                  icon="pi pi-bolt"
                ></p-tag>
                <p-tag
                  severity="primary"
                  value="{{ t('withKneepad') }}"
                  *ngIf="ascent.withKneepad"
                  icon="pi pi-ticket"
                ></p-tag>
              </div>
              <div class="ascent-table-bottom-row">
                <div class="ascent-table-properties">
                  <div class="grade">
                    <i
                      class="pi pi-angle-double-up"
                      style="color: green"
                      *ngIf="ascent | upgrade"
                    ></i>
                    <i class="pi pi-check" *ngIf="ascent | consensusGrade"></i>
                    <i
                      class="pi pi-angle-double-down"
                      style="color: red"
                      *ngIf="ascent | downgrade"
                    ></i>
                    <div class="ml-1 grade-name">
                      {{ ascent.grade.name | transloco }}
                      <span *ngIf="ascent.soft">&nbsp;({{ t("soft") }})</span>
                      <span *ngIf="ascent.hard">&nbsp;({{ t("hard") }})</span>
                    </div>
                  </div>
                  <p-tag
                    class="property-small"
                    severity="primary"
                    value="{{ t('fa') }}"
                    *ngIf="ascent.fa"
                    icon="pi pi-star"
                  ></p-tag>
                  <p-tag
                    class="property-small"
                    severity="primary"
                    value="{{ t('flash') }}"
                    *ngIf="ascent.flash"
                    icon="pi pi-bolt"
                  ></p-tag>
                  <p-tag
                    class="property-small"
                    severity="primary"
                    value="{{ t('withKneepad') }}"
                    *ngIf="ascent.withKneepad"
                    icon="pi pi-ticket"
                  ></p-tag>
                </div>
                <div class="ascent-table-rating">
                  <p-rating
                    [ngModel]="ascent.rating"
                    [readonly]="true"
                    [cancel]="false"
                  ></p-rating>
                </div>
                <div class="ascent-table-date">
                  <i class="pi pi-calendar"></i>
                  <ng-container *ngIf="ascent.year">{{
                    ascent.year
                  }}</ng-container>
                  <ng-container *ngIf="ascent.date">{{
                    ascent.date | date: "dd.MM.yyyy"
                  }}</ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>

  <div class="flex justify-content-center mt-5" *ngIf="hasNextPage">
    <p-button
      label="{{ t('loadMore') }}"
      (scrolled)="loadNextPage()"
      (click)="loadNextPage()"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [loading]="loadingAdditionalPage === loadingStates.LOADING"
    ></p-button>
  </div>

  <p-confirmPopup></p-confirmPopup>
</ng-container>
