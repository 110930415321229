/**
 * Defines identifiers for all notifications in the system.
 */
export enum NotificationIdentifier {
  MAP_MARKER_REMOVED = 'MAP_MARKER_REMOVED',
  MAP_MARKER_ADDED = 'MAP_MARKER_ADDED',
  GALLERY_IMAGE_CREATED = 'GALLERY_IMAGE_CREATED',
  GALLERY_IMAGE_UPDATED = 'GALLERY_IMAGE_UPDATED',
  GALLERY_IMAGE_DELETED = 'GALLERY_IMAGE_DELETED',
  PROJECT_CLIMBED_MESSAGE_SENT = 'PROJECT_CLIMBED_MESSAGE_SENT',
  TODO_DELETED = 'TODO_DELETED',
  TODO_PRIORITY_UPDATED = 'TODO_PRIORITY_UPDATED',
  TODO_ADD_ERROR = 'TODO_ADD_ERROR',
  TODO_ADDED = 'TODO_ADDED',
  ASCENT_ADDED = 'ASCENT_ADDED',
  ASCENT_UPDATED = 'ASCENT_UPDATED',
  ASCENT_DELETED = 'ASCENT_DELETED',
  LOGIN_ERROR = 'LOGIN_ERROR',
  USER_PROMOTED = 'USER_PROMOTED',
  USER_DELETED = 'USER_DELETED',
  CREATE_USER_MAIL_SENT = 'CREATE_USER_MAIL_SENT',
  ACCOUNT_SETTINGS_UPDATED = 'ACCOUNT_SETTINGS_UPDATED',
  INSTANCE_SETTINGS_UPDATED = 'INSTANCE_SETTINGS_UPDATED',
  REGION_UPDATED = 'REGION_UPDATED',
  USER_NOT_ACTIVATED = 'USER_NOT_ACTIVATED',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  COOKIES_ALLOWED = 'COOKIES_ALLOWED',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  AUTO_LOGOUT_SUCCESS = 'AUTO_LOGOUT_SUCCESS',
  UNKNOWN_AUTHENTICATION_ERROR = 'UNKNOWN_AUTHENTICATION_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  LOG_OUT_TO_USE_THIS_FUNCTION = 'LOG_OUT_TO_USE_THIS_FUNCTION',
  CRAG_CREATED = 'CRAG_CREATED',
  CRAG_UPDATED = 'CRAG_UPDATED',
  CRAG_DELETED = 'CRAG_DELETED',
  SECTOR_CREATED = 'SECTOR_CREATED',
  SECTOR_UPDATED = 'SECTOR_UPDATED',
  SECTOR_DELETED = 'SECTOR_DELETED',
  AREA_CREATED = 'AREA_CREATED',
  AREA_UPDATED = 'AREA_UPDATED',
  AREA_DELETED = 'AREA_DELETED',
  LINE_CREATED = 'LINE_CREATED',
  LINE_UPDATED = 'LINE_UPDATED',
  LINE_DELETED = 'LINE_DELETED',
  POST_CREATED = 'POST_CREATED',
  POST_UPDATED = 'POST_UPDATED',
  POST_DELETED = 'POST_DELETED',
  TOPO_IMAGE_ADDED = 'TOPO_IMAGE_ADDED',
  TOPO_IMAGE_UPDATED = 'TOPO_IMAGE_UPDATED',
  TOPO_IMAGE_DELETED = 'TOPO_IMAGE_DELETED',
  LINE_PATH_ADDED = 'LINE_PATH_ADDED',
  LINE_PATH_DELETED = 'LINE_PATH_DELETED',
  MENU_PAGE_DELETED = 'MENU_PAGE_DELETED',
  MENU_PAGE_UPDATED = 'MENU_PAGE_UPDATED',
  MENU_PAGE_CREATED = 'MENU_PAGE_CREATED',
  MENU_ITEM_DELETED = 'MENU_ITEM_DELETED',
  MENU_ITEM_UPDATED = 'MENU_ITEM_UPDATED',
  MENU_ITEM_CREATED = 'MENU_ITEM_CREATED',
  USER_REGISTERED = 'USER_REGISTERED',
}
