<ng-container *transloco="let t">
  <p-chart
    type="bar"
    [data]="data"
    [options]="options"
    *ngIf="grades"
  ></p-chart>
  <div class="total">
    {{ t("totalLines") }}: {{ totalCount - projectCount }}
    <span *ngIf="!excludeProjects"
      >({{ t("plusXProjects", { projects: projectCount }) }})</span
    >
  </div>
</ng-container>
