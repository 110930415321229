<ng-container *transloco="let t; read: 'topoImage.topoImageList'">
  <p-dataView
    #dv
    [value]="topoImages"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
    [emptyMessage]="t('noTopoImagesFoundEmptyMessage')"
    [loading]="loading === loadingStates.LOADING"
  >
    <ng-template pTemplate="header">
      <div class="flex flex-column sm:flex-row justify-content-between gap-2">
        <p-dropdown
          [options]="sortOptions"
          [(ngModel)]="sortKey"
          optionLabel="label"
          (onChange)="onSortChange($event)"
          styleClass="md:mb-0"
        >
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="sortKey">
              <i [class]="sortKey.icon" *ngIf="sortKey.icon"></i>
              <div>{{ sortKey.label }}</div>
            </div>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div class="flex align-items-center gap-2">
              <i [class]="option.icon" *ngIf="option.icon"></i>
              <div>{{ option.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <div class="flex justify-content-start sm:justify-content-end gap-3">
          <p-button
            label="{{ t('newTopoImageButtonLabel') }}"
            icon="pi pi-plus"
            class="responsive-button"
            routerLink="/topo/{{ cragSlug }}/{{ sectorSlug }}/{{
              areaSlug
            }}/add-topo-image"
            *isModerator="true"
          ></p-button>
          <p-button
            label="{{ t('reorderTopoImagesButtonLabel') }}"
            icon="pi pi-sort"
            (click)="reorderTopoImages()"
            [disabled]="topoImages?.length < 2"
            class="responsive-button"
            *isModerator="true"
          ></p-button>
        </div>
      </div>
    </ng-template>
    <ng-template let-topoImages pTemplate="list">
      <div class="grid grid-nogutter">
        <div
          class="col-12"
          *ngFor="let topoImage of topoImages; let first = first"
          data-cy="topo-image-list-item"
          [id]="topoImage.id"
        >
          <div
            class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4"
            [ngClass]="{ 'border-top-1 surface-border': !first }"
          >
            <div class="w-12 sm:w-9 xl:w-5 align-self-center">
              <lc-topo-image
                [topoImage]="topoImage"
                [showLineNumbers]="true"
                class="shadow-2 block xl:block mx-auto border-round"
              ></lc-topo-image>
              <lc-topo-image-details
                [topoImage]="topoImage"
              ></lc-topo-image-details>
            </div>
            <div
              class="flex flex-column sm:flex-column justify-content-between align-items-center xl:align-items-start flex-1 gap-4"
            >
              <div class="line-table">
                <div
                  class="flex table-header flex-column sm:flex-row gap-2 align-items-center"
                >
                  <p-message
                    severity="info"
                    *ngIf="topoImage.linePaths.length === 0"
                    text="{{ t('noLineAssignedToTopoImage') }}"
                  >
                  </p-message>
                  <div *ngIf="topoImage.linePaths.length > 0">
                    {{ t("lines") }}
                  </div>
                  <div
                    *isModerator="true"
                    class="justify-content-start sm:justify-content-end flex flex-grow-1"
                  >
                    <p-button
                      label="{{ t('addLineToImageButtonLabel') }}"
                      icon="pi pi-plus"
                      routerLink="/topo/{{ cragSlug }}/{{ sectorSlug }}/{{
                        areaSlug
                      }}/topo-images/{{ topoImage.id }}/add-line-path"
                      [disabled]="
                        topoImage.loadingState !== loadingStates.DEFAULT
                      "
                      styleClass="p-button-sm"
                      class="responsive-button"
                    ></p-button>
                    <p-button
                      label="{{ t('reorderLinePathsButtonLabel') }}"
                      icon="pi pi-sort"
                      (click)="reorderLinePaths(topoImage)"
                      [disabled]="topoImage.linePaths?.length < 2"
                      styleClass=" p-button-sm"
                      class="ml-2 responsive-button"
                    ></p-button>
                    <p-button
                      label="{{ t('editTopoImageButtonLabel') }}"
                      icon="pi pi-pencil"
                      class="ml-2 responsive-button"
                      routerLink="/topo/{{ cragSlug }}/{{ sectorSlug }}/{{
                        areaSlug
                      }}/topo-images/{{ topoImage.id }}/edit"
                      styleClass=" p-button-sm"
                    ></p-button>
                    <p-button
                      icon="pi pi-trash"
                      class="ml-2"
                      (click)="confirmDeleteTopoImage($event, topoImage)"
                      [loading]="
                        topoImage.loadingState === loadingStates.LOADING
                      "
                      styleClass="p-button-outlined p-button-danger p-button-sm"
                    ></p-button>
                  </div>
                </div>

                <div class="line-table-wrapper">
                  <a
                    class="line-table-row"
                    *ngFor="let linePath of topoImage.linePaths; index as i"
                    (mouseenter)="highlightLinePath(linePath, topoImage)"
                    (mouseleave)="unhighlightLinePath(linePath)"
                    [ngClass]="{ ticked: ticks.has(linePath.line.id) }"
                    routerLink="/topo/{{ cragSlug }}/{{ sectorSlug }}/{{
                      areaSlug
                    }}/{{ linePath.line.slug }}"
                    data-cy="line-row"
                  >
                    <div class="line-table-column-number">
                      <div class="line-number">
                        {{ i + 1 }}
                      </div>
                    </div>
                    <div class="line-table-column-info">
                      <div class="line-table-column-info-top-row">
                        <div class="line-table-info-name">
                          {{ linePath.line.name }}
                        </div>
                        <div class="line-table-info-grade">
                          <div class="grade">
                            <div class="grade-ellipsis">
                              {{ linePath.line.grade.name | transloco }}
                            </div>
                          </div>
                        </div>
                        <div class="line-table-info-style">
                          <lc-line-bool-prop-list
                            [line]="linePath.line"
                          ></lc-line-bool-prop-list>
                        </div>
                      </div>
                      <div class="line-table-column-info-bottom-row">
                        <div class="line-table-info-rating">
                          <p-rating
                            [ngModel]="linePath.line.rating"
                            [readonly]="true"
                            [cancel]="false"
                          ></p-rating>
                        </div>
                        <div class="line-table-info-actions">
                          <p-button
                            icon="pi pi-video"
                            styleClass="p-button-rounded p-button-text"
                            *ngIf="linePath.line.videos?.length > 0"
                            (click)="openVideo($event, linePath.line)"
                          >
                          </p-button>
                          <ng-container *isLoggedIn="true">
                            <lc-tick-button
                              [line]="linePath.line"
                              [ticked]="ticks.has(linePath.line.id)"
                            ></lc-tick-button>
                          </ng-container>
                          <ng-container *isModerator="true">
                            <p-button
                              icon="pi pi-trash"
                              styleClass="p-button-rounded p-button-danger p-button-text"
                              [loading]="
                                linePath.loadingState === loadingStates.LOADING
                              "
                              [disabled]="
                                topoImage.loadingState !== loadingStates.DEFAULT
                              "
                              (click)="
                                confirmDeleteLinePath(
                                  $event,
                                  linePath,
                                  topoImage
                                )
                              "
                            ></p-button>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>

  <p-confirmPopup></p-confirmPopup>
</ng-container>
