<ng-container *transloco="let t; read: 'searchDialog'">
  <div class="input-wrapper">
    <div class="p-input-icon-left">
      <i class="pi pi-search" *ngIf="!loading"></i>
      <i class="pi pi-spin pi-spinner" *ngIf="loading"></i>
      <input
        type="text"
        pInputText
        [(ngModel)]="query"
        (ngModelChange)="search()"
        placeholder="{{ t('searchInputPlaceholder') }}"
      />
    </div>
    <p-button icon="pi pi-times" (click)="close()"></p-button>
  </div>

  <div class="spacer" *ngIf="searchables?.length > 0"></div>
  <lc-searchable
    [searchable]="searchable"
    *ngFor="let searchable of searchables"
  >
  </lc-searchable>
</ng-container>
