<ng-container *transloco="let t; read: 'changePassword'">
  <div class="auth-container">
    <div class="card login-card">
      <h3>{{ t("changePassword") }}</h3>

      {{ t("changeYourPasswordHere") }}

      <form [formGroup]="changePasswordForm" lcForm>
        <div class="card-container block" lcControlGroup>
          <p-password
            formControlName="oldPassword"
            [toggleMask]="true"
            [feedback]="false"
            placeholder="{{ t('oldPasswordPlaceholder') }}"
            lcFormControl
          ></p-password>
          <small class="p-error" *lcIfError="'required'">{{
            t("required")
          }}</small>
          <small class="p-error" *lcIfError="'minlength'">{{
            t("minLength8")
          }}</small>
        </div>

        <div formGroupName="newPasswords">
          <div class="card-container block" lcControlGroup>
            <p-password
              formControlName="password"
              [toggleMask]="true"
              [feedback]="false"
              placeholder="{{ t('newPasswordPlaceholder') }}"
              lcFormControl
            ></p-password>
            <small class="p-error" *lcIfError="'required'">{{
              t("required")
            }}</small>
            <small class="p-error" *lcIfError="'minlength'">{{
              t("minLength8")
            }}</small>
          </div>

          <div class="card-container block" lcControlGroup>
            <p-password
              formControlName="passwordConfirm"
              [toggleMask]="true"
              [feedback]="false"
              placeholder="{{ t('confirmNewPasswordPlaceholder') }}"
              lcFormControl
            ></p-password>
            <small class="p-error" *lcIfError="'required'">{{
              t("required")
            }}</small>
            <small class="p-error" *lcIfError="'minlength'">{{
              t("minLength8")
            }}</small>
          </div>
        </div>
      </form>

      <p-message
        severity="error"
        text="{{ t('passwordsDontMatch') }}"
        class="mt-4"
        *ngIf="changePasswordPressed && passwordsDontMatch()"
      >
      </p-message>

      <p-button
        label="{{ t('changePasswordButtonLabel') }}"
        (click)="changePassword()"
        [loading]="loading"
      ></p-button>
    </div>
  </div>
</ng-container>
