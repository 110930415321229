<ng-container *transloco="let t; read: 'resetPassword'">
  <div class="auth-container">
    <div class="card login-card">
      <h3>{{ t("title") }}</h3>

      {{ t("description") }}

      <form [formGroup]="resetPasswordForm" lcForm>
        <div formGroupName="newPasswords">
          <div class="card-container block" lcControlGroup>
            <p-password
              formControlName="password"
              [toggleMask]="true"
              [feedback]="false"
              placeholder="{{ t('newPasswordPlaceholder') }}"
              lcFormControl
            ></p-password>
            <small class="p-error" *lcIfError="'required'">{{
              t("required")
            }}</small>
            <small class="p-error" *lcIfError="'minlength'">{{
              t("minLength8")
            }}</small>
            <small class="p-error" *lcIfError="'maxlength'">{{
              t("maxlength120")
            }}</small>
          </div>

          <div class="card-container block" lcControlGroup>
            <p-password
              formControlName="passwordConfirm"
              [toggleMask]="true"
              [feedback]="false"
              placeholder="{{ t('confirmNewPasswordPlaceholder') }}"
              lcFormControl
            ></p-password>
            <small class="p-error" *lcIfError="'required'">{{
              t("required")
            }}</small>
            <small class="p-error" *lcIfError="'minlength'">{{
              t("minLength8")
            }}</small>
            <small class="p-error" *lcIfError="'maxlength'">{{
              t("maxlength120")
            }}</small>
          </div>
        </div>
      </form>

      <p-message
        severity="error"
        text="{{ t('passwordsDontMatchAlertText') }}"
        class="mt-4"
        *ngIf="resetPasswordPressed && passwordsDontMatch()"
      >
      </p-message>

      <p-button
        label="{{ t('changePasswordButtonLabel') }}"
        (click)="resetPassword()"
        [loading]="(loadingState$ | async) === loadingStates.LOADING"
      ></p-button>
    </div>
  </div>
</ng-container>
