<ng-container *transloco="let t; read: 'menuItems.menuItemForm'">
  <div class="content">
    <p-card
      header="{{
        editMode ? t('editMenuItemTitle') : t('createMenuItemTitle')
      }}"
    >
      <p class="mt-0" *ngIf="!editMode">
        {{ t("createMenuItemDescription") }}
      </p>

      <form [formGroup]="menuItemForm" lcForm *ngIf="menuPages">
        <div class="flex flex-column gap-2" lcControlGroup>
          <label class="form-label" for="type">{{ t("typeLabel") }}</label>
          <p-dropdown
            formControlName="type"
            [options]="types"
            lcFormControl
            id="type"
          >
            <ng-template let-type pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div>{{ type | transloco }}</div>
              </div>
            </ng-template>
            <ng-template let-selectedItem pTemplate="selectedItem">
              {{ selectedItem | transloco }}
            </ng-template>
          </p-dropdown>
          <small class="p-error" *lcIfError="'required'">{{
            t("required")
          }}</small>
        </div>

        <div class="flex flex-column gap-2" lcControlGroup>
          <label class="form-label" for="position">{{
            t("positionLabel")
          }}</label>
          <p-dropdown
            id="position"
            formControlName="position"
            [options]="positions"
            lcFormControl
          >
            <ng-template let-position pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div>{{ position | transloco }}</div>
              </div>
            </ng-template>
            <ng-template let-selectedItem pTemplate="selectedItem">
              {{ selectedItem | transloco }}
            </ng-template>
          </p-dropdown>
          <small class="p-error" *lcIfError="'required'">{{
            t("required")
          }}</small>
        </div>

        <div class="flex flex-column gap-2" lcControlGroup>
          <label class="form-label" for="menu-page">{{
            t("menuPageLabel")
          }}</label>
          <p-dropdown
            id="menu-page"
            formControlName="menuPage"
            [options]="menuPages"
            lcFormControl
          >
            <ng-template let-menuPage pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div>{{ menuPage.title }}</div>
              </div>
            </ng-template>
            <ng-template let-selectedItem pTemplate="selectedItem">
              {{ selectedItem.title }}
            </ng-template>
          </p-dropdown>
          <small class="p-error" *lcIfError="'required'">{{
            t("required")
          }}</small>
        </div>

        <div class="flex flex-column gap-2" lcControlGroup>
          <label class="form-label" for="icon">{{ t("iconLabel") }}</label>
          <p-dropdown
            formControlName="icon"
            id="icon"
            [options]="icons"
            lcFormControl
          >
            <ng-template let-icon pTemplate="item">
              <div class="flex align-items-center gap-2">
                <i class="pi" [ngClass]="icon"></i>
                <div>{{ icon | transloco }}</div>
              </div>
            </ng-template>
            <ng-template let-selectedIcon pTemplate="selectedItem">
              <i class="pi" [ngClass]="selectedIcon"></i>
              {{ selectedIcon | transloco }}
            </ng-template>
          </p-dropdown>
          <small class="p-error" *lcIfError="'required'">{{
            t("required")
          }}</small>
        </div>
      </form>

      <div class="form-actions">
        <p-button
          label="{{ t('createMenuItemButtonLabel') }}"
          (click)="saveMenuItem()"
          icon="pi pi-save"
          class="responsive-button"
          [loading]="loadingState === loadingStates.LOADING"
          *ngIf="!editMode"
          data-cy="submit"
        ></p-button>
        <p-button
          label="{{ t('editMenuItemButtonLabel') }}"
          (click)="saveMenuItem()"
          icon="pi pi-save"
          class="responsive-button"
          [loading]="loadingState === loadingStates.LOADING"
          *ngIf="editMode"
        ></p-button>
        <p-button
          label="{{ t('cancelButtonLabel') }}"
          (click)="cancel()"
          class="ml-3 responsive-button"
          styleClass="p-button-secondary"
          icon="pi pi-ban"
        ></p-button>
        <p-button
          label="{{ t('deleteMenuItemButtonLabel') }}"
          (click)="confirmDeleteMenuItem($event)"
          icon="pi pi-trash"
          styleClass="p-button-danger"
          class="ml-3 responsive-button"
          [loading]="loadingState === loadingStates.LOADING"
          *ngIf="editMode"
        ></p-button>
        <p-confirmPopup></p-confirmPopup>
      </div>
    </p-card>
  </div>
</ng-container>
